<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="elencoUtenti"
      sort-by="tipo"
      class="elevation-1"
      dense
      hide-default-footer
    >
<!--  :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" dark small class="mb-2" dense v-clipboard="() => linkRegistrazione" v-clipboard:success="clipboardSuccessHandler" title="Copia negli appunti un url per la registrazione degli utenti di questo distributore">
                Ottieni link registrazione
              </v-btn>
              <v-spacer></v-spacer> 
              <v-btn color="warning" dark small class="mb-2 ml-5" v-bind="attrs" v-on="on" dense>
                Nuovo utente
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-subtitle>Attenzione! La procedura manuale deve essere utilizzata solo per casi particolari. E' consigliabile usare il Link Registrazione</v-card-subtitle>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" >
                      <v-text-field v-model="editedItem.first_name" label="Nome" :rules="rulesValidationRequired"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" >
                      <v-text-field v-model="editedItem.last_name" label="Cognome" :rules="rulesValidationRequired"></v-text-field>
                    </v-col>                
                    <v-col cols="12" sm="6" md="4" > 
                      <v-text-field v-model="editedItem.email" label="Email" :rules="rulesValidationEmail"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" v-if="editedIndex === -1"> 
                      <v-text-field v-model="editedItem.password" label="Password" :rules="rulesValidationRequired"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-switch v-model="editedItem.attivo" label="Attivo"></v-switch>
                    </v-col>                  
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                >
                  Annulla
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                >
                  Salva
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Sei sicuro di voler cancellare questo utente?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Annulla</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.modules`]="{ item }">
        {{ item.modules.map(x => x.nome).join(', ') }}
      </template>
      <template v-slot:[`item.attivo`]="{ item }">
        <v-simple-checkbox v-model="item.attivo" disabled />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        Nessun utente definito
      </template>
      <template v-slot:expanded-item="{ }">
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackColor" :multi-line="true">
      <span v-html="snackText"></span>
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          OK
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  /* eslint-disable */
  import { validazioni } from '@/mixins/validazioni.js'
  import rivenditori from '@/services/rivenditoriService.js'

  export default {
    components: {
    },
    mixins: [
      validazioni
    ],
    props: {
      codiceRivenditore: String,
      linkRegistrazione: String,
      utenti: Array,
      refresh: Number
    },
    data: () => ({
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: 'Nome',
          align: 'start',
          sortable: false,
          value: 'first_name',
        },
        { text: 'Cognome', value: 'last_name' },
        { text: 'Email', value: 'email' },
        { text: 'Ruoli', value: 'roles' },
        { text: 'Moduli', value: 'modules' },
        { text: 'Attivo', value: 'attivo' },
        { text: 'Actions', value: 'actions', sortable: false }
        /* { text: '', value: 'data-table-expand' } */
      ],
      updateTrigger: 0,
      editedIndex: -1,
      // TODO: RIVEDERE IL CARICAMENTO DEI MODULI E LA MODALITA' DI CREAZIONE DELLA DIALOG DI INSERIEMTNO LICENZA--> METTERE COMPONENTI
      editedItem: {
        first_name: '',
        last_name: '',
        email: '',
        attivo:  true,
        roles: ['user'],
        audience: ['dashboard', 'graph', 'pdf']
      },
      defaultItem: {
        first_name: '',
        last_name: '',
        email: '',
        attivo:  true,
        roles: ['user'],
        audience: ['dashboard', 'graph', 'pdf']
      },
      expanded: [],
      singleExpand: false,
      snackbar: false,
      snackColor: '',
      snackText: '',
      timeout: 4000,
      elencoUtenti: []
    }),
    async mounted() {
      await this.getDataFromApi()
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuovo utente' : 'Modifica utente'
      },
      rulesValidationRequired() {
        let rl = []
        rl.push(this.rules.required)
        return rl
      },
      rulesValidationEmail() {
        let rl = []
        rl.push(this.rules.required)
        rl.push(this.rules.email)
        return rl
      }
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      async refresh() {
        await this.getDataFromApi()
      }      
    },
    methods: {
      async getDataFromApi() {
        if (this.utenti.length > 0) {
          this.elencoUtenti = await rivenditori.getUtentiRivenditore(this.utenti)
        }
      },
      editItem (item) {
        this.editedIndex = this.utenti.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.utenti.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
         this.$emit('delete-user', this.codiceRivenditore, this.editedItem)
        this.utenti.splice(this.editedIndex, 1)
        this.closeDelete()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.utenti[this.editedIndex], this.editedItem)
          this.$emit('update-user', this.codiceRivenditore, this.editedItem)
        } else {
          this.$emit('new-user', this.codiceRivenditore, this.editedItem)
          this.utenti.push(this.editedItem)
        }
        this.close()
      },
      clipboardSuccessHandler ({ value, event }) {
        this.snackColor = 'success'
        this.timeout = 10000
        this.snackText = 'Il link di registrazione è stato copiato negli appunti.<br/> Comunicalo al rivenditore per consentirgli la registrazione degli utenti !'
        this.snackbar = true
      }      
    }
  }
</script>

<style lang="scss">
  tr.v-data-table__expanded__content  { // TODO: Solo su questa pagina !
    box-shadow: none !important;
  }
  
  td.utenteSelected {
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #119432 !important;
  }
</style>
