<template>
  <div>
    <v-data-table
      v-model="selected"
      :show-select="totaleRivenditori > 0"
      item-key="codice"
      :headers="headers"
      :items="rivenditoriSorted"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
      :disable-sort="true"
      :footer-props="{
        itemsPerPageOptions: [5, 10, 15, 50, 100]
      }"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
    >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Rivenditori e Licenze</v-toolbar-title>
<!--    <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="onNuovoRivenditore">Nuovo Rivenditore</v-btn>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Confermi cancellazione rivenditore?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">SI</v-btn>
              <v-btn color="blue darken-1" text @click="closeDelete">NO</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <dialog-errore :show="dialogErrore" title="Errore aggiornamento Rivenditore" />
      </v-toolbar>
      <!-- <filtri @ricerca="onRicerca"/> -->
    </template>
    <template v-slot:[`item.dataScadenza`]="{ item }">
      {{item.dataScadenza | date}}
    </template>
    <template v-slot:[`item.attivo`]="{ item }">
      <v-simple-checkbox v-model="item.attivo" disabled />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on">mdi-pencil</v-icon>
        </template>
        <span>Modifica il rivenditore</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small @click="deleteItem(item)" v-bind="attrs" v-on="on">mdi-delete</v-icon>
        </template>
        <span>Cancella il rivenditore</span>
      </v-tooltip>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="getDataFromApi">Ricarica</v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="rivenditoriSelected">
        <v-tabs dense v-model="tab[item.codice]">
          <v-tab class="rivenditoriSelected">Utenti<v-btn icon @click="trigRefreshUtenti++"><v-icon>mdi-refresh</v-icon></v-btn></v-tab>
<!--           <v-tab class="rivenditoriSelected">Licenze{{item.name}}</v-tab>
          <v-tab class="rivenditoriSelected">Database</v-tab>
          <v-tab class="rivenditoriSelected">Configurazioni</v-tab>
          <v-tab class="rivenditoriSelected">Utenti</v-tab>
          <v-tab class="rivenditoriSelected">Installazione</v-tab>
          <v-tab class="rivenditoriSelected">Migrazione</v-tab>
          <v-tab class="rivenditoriSelected">Utility</v-tab> -->
        </v-tabs>
        <v-tabs-items v-model="tab[item.codice]">
          <v-tab-item>
            <span class="mt-2 mb-2 text-caption" v-html="`
              La procedura corretta prevede la generazione di un link di registrazione ed il suo invio tramite email al rivenditore.<br />
              Il link permette la registrazione di un numero qualsiasi di utenti collegati al rivenditore.<br />
              <strong>Non è necessaria la generazione manuale dell'utente!</strong>
            `">
            </span>
            <dettaglio-utenti :utenti="item.utenti" :codiceRivenditore="item.codice" :linkRegistrazione="linkRegistrazione(item)" :refresh="trigRefreshUtenti" @update-user="updateUser" @new-user="newUser" @delete-user="deleteUser"/>
          </v-tab-item>          
<!--           <v-tab-item>
            <dettaglio-licenze :licenze="item.licenze" :codiceRivenditore="item.codice" @update-licenza="updateLicence" @new-licenza="newLicence"/>
          </v-tab-item>
          <v-tab-item>
            <dettaglio-database :rivenditore="item" />
          </v-tab-item>
          <v-tab-item>
            <dettaglio-configurazioni :rivenditore="item" />
          </v-tab-item>          

          <v-tab-item>
            <dettaglio-installazione :rivenditore="item" />
          </v-tab-item>
          <v-tab-item>
            <dettaglio-migrazione :rivenditore="item" />
          </v-tab-item>
          <v-tab-item>
            <dettaglio-utility :rivenditore="item" />
          </v-tab-item> -->
          <!-- <v-tab-item></v-tab-item>  -->
          <!-- Step Procedura setup + Stampa lista lavoro (apre pagina stampabile con le info per l'installazione e le procedura da effettaure) -->
<!--           <v-tab-item>
          </v-tab-item> -->
        </v-tabs-items>
      </td>
    </template>    
    </v-data-table>
    <!-- <speed-dial /> -->
  </div>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid'
  import { dynamicSortMultiple } from '@/components/helpers.js'
  import { verificaLicenza } from '@/mixins/verificaLicenza.js'
  import DialogErrore from '@/components/dialogErrore'

  import DettaglioUtenti from './elementi/utenti'

/*   import DettaglioLicenze from './elementi/licenze'
  import DettaglioDatabase from './elementi/database'
  
  import DettaglioMigrazione from './elementi/migrazione'
  import DettaglioUtility from './elementi/utility'
  import DettaglioConfigurazioni from './elementi/configurazioni'
  import DettaglioInstallazione from './elementi/installazione.vue' */

  import rivenditori from '@/services/rivenditoriService.js'
  // import licenze from '@/services/licenzeService.js'
  
  export default {
    components: {
      DialogErrore,
      DettaglioUtenti
/*       DettaglioLicenze,
      DettaglioDatabase,
      DettaglioMigrazione,
      DettaglioUtility,
      DettaglioConfigurazioni,
      DettaglioInstallazione */
    },
    mixins: [verificaLicenza],
    data () {
      return {
        totaleRivenditori: 0,
        rivenditori: [],
        selected: [],
        loading: true,
        options: {},
        headers: [
          { text: 'Codice', value: 'codice', align: 'start' },
          { text: 'Ragione sociale', value: 'ragioneSociale' },
          { text: 'Telefono', value: 'anagrafica.telefono' },
          { text: 'Partita Iva', value: 'amministrativi.partivaIva' },
          { text: 'Base DB', value: 'baseDb' },
/*           { text: 'Base DB', value: 'baseDb' },
          { text: 'Data scadenza', value: 'dataScadenza' },          
          { text: 'Attivo', value: 'attivo' }, */
          { text: 'Actions', value: 'actions', sortable: false },
          { text: '', value: 'data-table-expand' }
        ],
        expanded: [],
        singleExpand: false,
        dialogErrore: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: null,
        defaultItem: null,
        filtri: null,
        tab: {},
        trigRefreshUtenti: 0
      }
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuovo Rivenditore' : 'Modifica Rivenditore'
      },
      customerSaved() {
        return this.$store.getters['rivenditori/saved']
      },
      refreshList() {
        return this.$store.getters['rivenditori/refresh']
      },
      rivenditoriSorted() {
        const els = this.rivenditori
        const elSorted = els.sort(dynamicSortMultiple('ragioneSociale'))
        return elSorted
      },
      serviceBase() {
        return window.location.origin
      }
    },
    watch: {
      options: {
        async handler () {
          await this.getDataFromApi()
        },
        deep: true
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      async customerSaved(value) {
        if (value) {
          await this.save()
        }
      },
      async refreshList(value) {
        if (value) {
          await this.getDataFromApi()
          this.$store.commit('rivenditori/CLEAR_REFRESH')
        }
      }
    },
    async created() {
      this.defaultItem = await rivenditori.getModello()
    },
    async mounted () {
      await this.verificaModuliLocali()
      // await this.gestioneToken()
      await this.getDataFromApi()
    },
    methods: {
      linkRegistrazione(item) {
        return `${this.serviceBase}/register/${item.linkRegistrazione || ''}`
      },
      async onRicerca(filtri) {
        this.filtri = filtri
        await this.getDataFromApi()
      },
      async getDataFromApi () {
        try {
          this.loading = true
          const rivenditoriRaw = await rivenditori.getRivenditori()
          // this.rivenditoriRaw = data && data.total_rows > 0 ? data.rows.map(x => x.doc) : []
          // this.rivenditoriRaw = data && data.total_rows > 0 ? data.rows : []
          this.rivenditori = this.datiVisualizzazione(rivenditoriRaw)
          this.totaleRivenditori = rivenditoriRaw.length
        } catch(err) {
          this.rivenditori = []
          this.totaleRivenditori = 0
        } finally {
          this.loading = false
        }
      },
      onNuovoRivenditore() {
        this.editedIndex = -1
        this.editedItem = Object.assign({}, this.defaultItem)
        this.$store.commit('rivenditori/SET_RIVENDITORE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      editItem (item) {
        this.editedIndex = this.rivenditori.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.$store.commit('rivenditori/SET_RIVENDITORE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      duplicateItem(item) {
        this.editedIndex = -1
        this.editedItem = Object.assign({}, item)
        this.editedItem._id = ''
        delete this.editedItem._rev
        this.$store.commit('rivenditori/SET_RIVENDITORE', this.editedItem)
        this.$store.commit('SET_RIGHT_DRAWER_READ_ONLY', false)
        this.$store.commit('SET_RIGHT_DRAWER', true)
      },
      deleteItem (item) {
        this.editedIndex = this.rivenditori.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      async deleteItemConfirm () {
        this.rivenditori.splice(this.editedIndex, 1)
        // TODO: ATTENZIONE VA VERIFICATO CHE NON ABBIA STORIA
        if (!await rivenditori.cancellaRivenditore(this.editedItem)) {
          this.dialogErrore = true
        }
        this.closeDelete()
      },

      close () {
        this.$store.commit('SET_RIGHT_DRAWER', false)
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      async save () {
        this.$store.commit('rivenditori/CLEAR_RIVENDITORE_SAVED')
        this.editedItem = this.$store.getters['rivenditori/rivenditore']
        if (this.editedIndex > -1) {
          this.editedItem = this.deleteDatiVisualizzazione(this.editedItem)
          Object.assign(this.rivenditori[this.editedIndex], this.editedItem)
          if (!await rivenditori.modificaRivenditore(this.editedItem)) {
            this.dialogErrore = true
            return
          }
        } else {
          this.editedItem.linkRegistrazione = uuidv4()
          this.rivenditori.push(this.editedItem)
          if (!await rivenditori.creaRivenditore(this.editedItem)) {
            this.dialogErrore = true
            return
          }
        }
        this.close()
        // va fatto il refresh della lista per prendere gli aggiornamenti
        await this.getDataFromApi()
      },
      erroreConfirm() {
        this.dialogErrore = false
      },
      datiVisualizzazione(rivenditori) {
        const elaborati = rivenditori.map(el => {
          const recapito = el.recapiti && el.recapiti.length > 0 ? el.recapiti.find(x => x.tipo === 'principale') : null
          const indirizzo = recapito ? `${recapito.indirizzo}, ${recapito.localita} ${recapito.cap} - ${recapito.provincia}` : ''
          const contatto = el.contatti && el.contatti.length > 0 ? el.contatti.find(x => x.tipo === 'titolare') : null
          const titolare = contatto ? `${contatto.nome} ${contatto.cognome} - cell.${contatto.cellulare} - email: ${contatto.email}` : ''
          el.utenti = el.utenti || []
          return { ...el, c_recapito: recapito, c_indirizzo: indirizzo, c_contatto: contatto, c_titolare: titolare }
        }) 
        return elaborati
      },
      deleteDatiVisualizzazione(rivenditore) {
        let locRivenditore = {...rivenditore}
        // Eventuale aggiornamento dati modificati
        delete locRivenditore.c_recapito
        delete locRivenditore.c_indirizzo
        delete locRivenditore.c_contatto
        delete locRivenditore.c_titolare
        return locRivenditore
      },
      async newUser(codiceRivenditore, utente) {
        // TODO: Verificare
        // await licenze.creaUtente(codiceRivenditore, utente)
      },
      async updateUser(codiceRivenditore, utente) {
        // await licenze.aggiornaUtente(codiceRivenditore, utente)
      },
      async deleteUser(codiceRivenditore, utente) {
        // await licenze.cancellaUtente(codiceRivenditore, utente)
      }
    }
  }
</script>

<style lang="scss">
  tr.v-data-table__expanded__content  { // TODO: Solo su questa pagina !
    box-shadow: none !important;
  }
  
  td.rivenditoriSelected {
    border-style: solid!important;
    border-width: 2px!important;
    border-color: #1976d2 !important;
  }

</style>
